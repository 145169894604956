import { FormEventHandler, useState, useEffect } from "react";
import { ReactComponent as Send } from "./send.svg";
import { ReactComponent as XIcon } from "./x-icon.svg";
import Loader from "./Loader";
import Bot from "./components/Bot";
import Message, { IMessage } from "./components/Message";
import PasswordForm from "./components/PasswordForm";
import "./App.css";

function App() {
  const [prompt, setPrompt] = useState("");
  const [auth, setAuth] = useState("getclearfuture");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);

  const _sendMessages = async (submittingMessages: IMessage[]) => {
    const reqPrompt = submittingMessages.slice(-30).map((m) => ({
      role: m.user.type === "self" ? "user" : "assistant",
      content: m.message,
    }));

    const res = await fetch("https://getclearbot-krlsm6ogea-uw.a.run.app", {
      method: "POST",
      body: JSON.stringify({
        prompt: reqPrompt,
        password: auth,
      }),
    });

    if (res.status === 401) {
      setAuth("");
      setMessages([]);
      return;
    }

    const json = await res.json();

    if (json?.choices?.[0]?.message) {
      setMessages((state) => [
        ...state,
        {
          id: Date.now().toString(),
          user: { type: "bot" },
          message: json.choices[0].message.content.trim(),
        },
      ]);
    }
  };

  const sendPrompt: FormEventHandler = async (e) => {
    e.preventDefault();

    if (loading || !auth) return;

    setLoading(true);
    const newMessages = [
      ...messages,
      {
        id: Date.now().toString(),
        user: { type: "self" as "self" | "bot" },
        message: prompt,
      },
    ];
    setMessages(newMessages);
    setPrompt("");

    const conversationEl = document.getElementById("chat-conversation");
    if (conversationEl) {
      setTimeout(
        () => (conversationEl.scrollTop = conversationEl.scrollHeight),
        0
      );
    }

    try {
      await _sendMessages(newMessages);

      if (conversationEl) {
        setTimeout(
          () => (conversationEl.scrollTop = conversationEl.scrollHeight),
          0
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const refetchMessage = async () => {
    if (loading || !auth) return;

    setLoading(true);
    const newMessages = messages.slice(0, -1);
    setMessages(newMessages);

    const conversationEl = document.getElementById("chat-conversation");

    if (conversationEl) {
      setTimeout(
        () => (conversationEl.scrollTop = conversationEl.scrollHeight),
        0
      );
    }

    try {
      await _sendMessages(newMessages);

      if (conversationEl) {
        setTimeout(
          () => (conversationEl.scrollTop = conversationEl.scrollHeight),
          0
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const pass = localStorage.getItem("chatbotp");
  //   setAuth(pass ?? "");
  // }, [setAuth]);
  //
  // if (!auth) {
  //   return (
  //     <PasswordForm
  //       savePassword={(password) => {
  //         setAuth(password);
  //         localStorage.setItem("chatbotp", password);
  //       }}
  //     />
  //   );
  // }

  return (
    <main className="App">
      <section id="chat-conversation" className="Chat-conversation">
        {!messages.length && <Bot />}
        {messages.map((m, i) => (
          <Message
            key={m.id}
            index={i}
            message={m}
            allMessages={messages}
            refetchMessage={refetchMessage}
          />
        ))}
        {loading && (
          <div className="Chat-loader">
            <Loader />
          </div>
        )}
      </section>
      <form onSubmit={sendPrompt} className="Prompt-form">
        <div className="Prompt-container">
          <div className="Prompt-textarea">
            <textarea
              maxLength={500}
              className="Prompt"
              name="prompt"
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  sendPrompt(e);
                }
              }}
              value={prompt}
            />
          </div>
          <p className="Prompt-char-count">{prompt.length}/500</p>
          <div className="Prompt-submit-container">
            <button
              className="Prompt-cancel"
              type="button"
              onClick={() => setPrompt("")}
            >
              <XIcon className="x-icon" />
            </button>
            <button disabled={loading} className="Prompt-submit" type="submit">
              {loading ? (
                <div className="Loader">
                  <Loader />
                </div>
              ) : (
                <Send className="Send" />
              )}
            </button>
          </div>
        </div>
      </form>
    </main>
  );
}

export default App;
