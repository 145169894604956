import { FC } from "react";
import botGif from "./bot.gif";
import bubbleImg from "./bubble.png";
import "./Bot.css";

const Bot: FC = () => (
  <div className="Bot-img-container">
    <img className="Bot-img" src={botGif} alt="Bot" />
    <div className="Bot-bubble-container">
      <img className="Bot-img-bubble" src={bubbleImg} alt="Speech bubble" />
      <div className="type-container">
        <span className="type" style={{ "--n": 69 } as React.CSSProperties}>
          Hi, I’m JoBot, your online marketing expert. What can I help you with?
        </span>
      </div>
    </div>
  </div>
);

export default Bot;
