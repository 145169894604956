import { FC } from "react";
import { ReactComponent as CopyIcon } from "./copy-icon.svg";
import { ReactComponent as RefreshIcon } from "./refresh.svg";
import "./Message.css";

export interface IMessage {
  id: string;
  user: { type: "self" | "bot" };
  message: string;
}

export interface MessageProps {
  index: number;
  message: IMessage;
  allMessages: IMessage[];
  refetchMessage: () => Promise<void>;
}

const Message: FC<MessageProps> = ({
  index: i,
  message: m,
  allMessages,
  refetchMessage,
}) => {
  const replaceLeading = (value: string): string =>
    value.replace(/^\d+\./, "").replace(/^-/, "");

  const copyToClipboard = (value: string): void => {
    navigator.clipboard.writeText(replaceLeading(value).trim());
  };

  const isCopyable = (value: string): boolean =>
    /^\d+\./.test(value) || /^-/.test(value);

  return (
    <>
      <div className={`Message ${m.user.type}`}>
        <div className="Message-container">
          <div className="Message-text">
            {m.message.split("\n").map((value, index) => (
              <div key={index} className="Message-line">
                {isCopyable(value) && (
                  <button
                    className="Message-control-button"
                    type="button"
                    onClick={() => copyToClipboard(value)}
                    title="Copy Text"
                  >
                    <CopyIcon className="Message-control-button-icon" />
                  </button>
                )}
                {isCopyable(value) ? replaceLeading(value) : value}
                <br />
              </div>
            ))}
            {m.user.type === "bot" && (
              <div className="Message-controls">
                {i === allMessages.length - 1 && (
                  <button
                    className="Message-control-button"
                    type="button"
                    onClick={refetchMessage}
                    title="Try again"
                  >
                    <RefreshIcon className="Message-control-button-icon" />
                  </button>
                )}
                <button
                  className="Message-control-button"
                  type="button"
                  onClick={() => copyToClipboard(m.message)}
                  title="Copy Text"
                >
                  <CopyIcon className="Message-control-button-icon" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {allMessages[i + 1]?.user?.type !== m.user.type && (
        <div className={`Message-user-icon ${m.user.type}`}>
          {m.user.type === "self" ? "ME" : ""}
        </div>
      )}
    </>
  );
};

export default Message;
